.pageContent-wrapper {
  align-self: stretch;
  background: #f8f8f8;
  width: 100%;
  flex-direction: row;
}

.pageContent {
  max-width: 1600px;
  width: 100%;
  margin: 0px auto 0 auto;
  padding: 30px 20px;
}
