.headerBar-logo-link {
  border: none;
  font-size: 0;
}

.headerBar-logo {
  max-width: 110px;
  margin-left: 10px;
}

.headerBar-separator {
  width: 2px;
  background-color: white;
  margin-left: 20px;
  margin-right: 20px;
  opacity: 0.5;
  align-self: stretch;
  display: none;
}

/* Double up class to increase specificity */
.headerBar-separator.headerBar-separator {
  margin-left: 20px;
}

.headerBar-title {
  color: white;
  font-weight: 600;
  display: none;
}

@media screen and (min-width: 900px) {
  .headerBar-separator,
  .headerBar-title {
    display: block;
  }
}
